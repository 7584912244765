.invoices-detail-summary {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 3rem;

  @include media-breakpoint-down('sm') {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 2rem;
    margin-top: 2rem;
    width: 100%;
    padding: 0 2rem 2rem 2rem;

    .invoices-detail-resume {
      max-width: 100%;
    }
  }
}

.invoices-detail-resume {
  background-color: $slate-50;
  border: 1px solid $slate-200;
  border-radius: 0.8rem;
  padding: 1.6rem 1.6rem 2.4rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  max-width: 32rem;
  width: 100%;
  height: 100%;
}

.invoices-global-invoice-folio {
  background-color: $green-100;
  cursor: pointer;
  border-radius: 0.8rem;
  gap: 0.4rem;
  padding: 0.4rem 0.8rem;
  & > p {
    text-decoration: underline;
    color: $green-700;
  }
}

.invoices-auto-invoice-number {
  background-color: $slate-200;
  border-radius: 0.8rem;
  gap: 0.4rem;
  padding: 0.4rem 0.8rem;
  & > p {
    text-decoration: underline;
    color: $slate-500;
  }
}

.auto-invoice {
  &-notification {
    &-tag {
      width: fit-content;
      background-color: $indigo-100;
      padding: 0.4rem 0.8rem;
      border-radius: 0.8rem;
      color: $indigo-700;
      & > p {
        color: $indigo-700;
      }
    }

    &-message {
      max-width: 29em;
      text-align: center;
      margin-bottom: 8rem;
    }

    &-footer {
      button {
        width: 45%;
      }
    }
  }
}
